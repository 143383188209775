.main-div-about-me-wrapper {
	width: 100%;
	height: 100vh;
	background: linear-gradient(-45deg, #3b1c13, #471126, #092e3b, #062921);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.line-up-img {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.gradient-left {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
}

.white-right {
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(-45deg, #ffffff, #ffe7f0, #d0f2ff, #e4fff9);
	width: 50%;
	height: 100%;
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

#about-me-img {
	position: absolute;
	height: 100vh;
	object-fit: cover;
	padding-left: 200px;
	z-index: 10;
}

#ab-me-img-div {
	position: absolute;
	height: 100vh;
	/* z-index: 10; */
	width: 100vw;
	display: flex;
	justify-content: center;
}

#prev-bt {
	position: absolute;
	top: 1vh;
	left: 40vw;
	height: 23px;
	transform: scaleX(-1);
	filter: invert(96%) sepia(20%) saturate(7145%) hue-rotate(178deg) brightness(109%) contrast(98%);
	z-index: 20;
	opacity: .5;
	transition: 2s;
}

#next-bt {
	position: absolute;
	top: 1vh;
	right: 40vw;
	height: 20px;
	filter: invert(11%) sepia(24%) saturate(3121%) hue-rotate(158deg) brightness(100%) contrast(94%);
	z-index: 20;
	opacity: .5;
	transition: 2s;
}

#prev-bt:hover {
	cursor: pointer;
}

#next-bt:hover {
	cursor: pointer;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.light-about-me {
	position: absolute;
	color: white;
	opacity: 0;
	transition: 5s;
	width: 300px;
	height: 300px;
	z-index: 1;
}

.light-about-me:hover {
	cursor: default;
}

.light-about-me-continue:hover {
	cursor: default;
}

.light-about-me-continue2:hover {
	cursor: default;
}

.light-about-me-continue3:hover {
	cursor: default;
}


.dark-about-me {
	position: absolute;
	padding-left: 45px;
	width: 300px;
	height: 300px;
	color: black;
	opacity: 0;
	transition: 5s;
	z-index: 1;
}

.dark-about-me:hover {
	cursor: default;
}

.dark-about-me-continue:hover {
	cursor: default;
}

.dark-about-me-continue2:hover {
	cursor: default;
}

.dark-about-me-continue3:hover {
	cursor: default;
}


.dark-about-me-continue {
	position: absolute;
	color: black;
	padding-left: 45px;
	width: 300px;
	height: 300px;
	opacity: 0;
	transition: 5s;
}

.dark-about-me-continue2 {
	position: absolute;
	color: black;
	opacity: 0;
	transition: 5s;
}

.dark-about-me-continue3 {
	position: absolute;
	color: black;
	padding-left: 45px;
	opacity: 0;
	transition: 5s;
	height: 300px;
	width: 300px;
	gap: 10px;
}

.dark-about-me-continue4 {
	position: absolute;
	color: black;
	opacity: 0;
	transition: 5s;
	display: grid;
	grid-template-columns: repeat(2, 150px);
	height: 300px;
	gap: 10px;
}

#aboutme-icons-a {
	height: 100px;
	width: 100px;
	object-fit: cover;
	transition: 2s;
	animation: float 6s ease-in-out infinite;
}

#aboutme-icons-b {
	height: 100px;
	width: 100px;
	object-fit: cover;
	transition: 2s;
	animation: float2 6s ease-in-out infinite;
}

#aboutme-icons-c {
	height: 100px;
	width: 100px;
	object-fit: cover;
	transition: 2s;
	animation: float3 6s ease-in-out infinite;
}

#aboutme-icons2 {
	height: 100px;
	width: 100px;
	object-fit: cover;
	transition: 2s;
	animation: float4 6s ease-in-out infinite;
}

#aboutme-icons2:hover {
	cursor: pointer;
	filter: invert(60%) sepia(33%) saturate(7462%) hue-rotate(326deg) brightness(108%) contrast(109%);
}

#aboutme-icons-a:hover {
	cursor: pointer;
	filter: invert(60%) sepia(33%) saturate(7462%) hue-rotate(326deg) brightness(108%) contrast(109%);
}

#aboutme-icons-b:hover {
	cursor: pointer;
	filter: invert(60%) sepia(33%) saturate(7462%) hue-rotate(326deg) brightness(108%) contrast(109%);
}

#aboutme-icons-c:hover {
	cursor: pointer;
	filter: invert(60%) sepia(33%) saturate(7462%) hue-rotate(326deg) brightness(108%) contrast(109%);
}

#aboutme-icons2:hover {
	cursor: pointer;
}


@keyframes float {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(-10px);
	}

	100% {
		transform: translatey(0px);
	}
}


@keyframes float2 {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(15px);
	}

	100% {
		transform: translatey(0px);
	}
}



@keyframes float3 {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(-20px);
	}

	100% {
		transform: translatey(0px);
	}
}



@keyframes float4 {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(9px);
	}

	100% {
		transform: translatey(0px);
	}
}



.light-about-me-continue {
	position: absolute;
	color: white;
	opacity: 0;
	width: 300px;
	height: 300px;
	transition: 5s;
}

.light-about-me-continue2 {
	position: absolute;
	color: white;
	opacity: 0;
	transition: 5s;
}

.light-about-me-continue3 {
	opacity: 0;
	transition: 5s;
}

.light-about-me-continue4 {
	position: absolute;
	opacity: 0;
	transition: 5s;
	top: 0px;
	left: 240px;
}

#ty {
	height: 99vh;
}

.words {
	font-family: 'Nunito';
	font-weight: 800;
	color: transparent;
	font-size: 0;
	line-height: 1.5;

}

.words span {
	font-size: 5rem;
	display: inline-block;
	animation: move 3s ease-in-out infinite;

}

@keyframes move {
	0% {
		transform: translate(-30%, 0);
	}

	50% {
		text-shadow: 0 25px 50px rgba(0, 0, 0, 0.9);
	}

	100% {
		transform: translate(30%, 0);
	}
}

.words span:nth-child(2) {
	animation-delay: 0.5s;
}

.words span:nth-child(3) {
	animation-delay: 1s;
}

.words span:nth-child(4) {
	animation-delay: 1.5s;
}

.words span:nth-child(5) {
	animation-delay: 2s;
}

.words span:nth-child(6) {
	animation-delay: 2.5s;
}

.words span:nth-child(7) {
	animation-delay: 3s;
}

.sign {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 50%;
	background-image: radial-gradient(ellipse 50% 35% at 50% 50%,
			#8ebbff,
			transparent);
	transform: translate(-50%, -50%);
	letter-spacing: 2;
	left: 160px;
	top: -40px;
	font-family: "Nunito";
	text-transform: uppercase;
	font-size: 40px;
	color: #e6f0ff;
	text-shadow: 0 0 0.6rem #e6faff, 0 0 1.5rem #65edff,
		-0.2rem 0.1rem 1rem #65e8ff, 0.2rem 0.1rem 1rem #65f5ff,
		0 -0.5rem 2rem #24ccff, 0 0.5rem 3rem #24c1ff;
	animation: shine 2s forwards, flicker 3s infinite;
}

@keyframes blink {

	0%,
	22%,
	36%,
	75% {
		color: #e6f0ff;
		text-shadow: 0 0 0.6rem #e6faff, 0 0 1.5rem #65edff,
			-0.2rem 0.1rem 1rem #65e8ff, 0.2rem 0.1rem 1rem #65f5ff,
			0 -0.5rem 2rem #24ccff, 0 0.5rem 3rem #24c1ff;
	}

	28%,
	33% {
		color: #65f5ff;
		text-shadow: none;
	}

	82%,
	97% {
		color: #24ccff;
		text-shadow: none;
	}
}

.flicker {
	animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
	animation: shine 2s forwards, blink 5s 2s infinite;
}

@keyframes shine {
	0% {
		color: #18396b;
		text-shadow: none;
	}

	100% {
		color: #e6f0ff;
		text-shadow: 0 0 0.6rem #e6faff, 0 0 1.5rem #65edff,
			-0.2rem 0.1rem 1rem #65e8ff, 0.2rem 0.1rem 1rem #65f5ff,
			0 -0.5rem 2rem #24ccff, 0 0.5rem 3rem #24c1ff;
	}
}

@keyframes flicker {
	from {
		opacity: 1;
	}

	4% {
		opacity: 0.9;
	}

	6% {
		opacity: 0.85;
	}

	8% {
		opacity: 0.95;
	}

	10% {
		opacity: 0.9;
	}

	11% {
		opacity: 0.922;
	}

	12% {
		opacity: 0.9;
	}

	14% {
		opacity: 0.95;
	}

	16% {
		opacity: 0.98;
	}

	17% {
		opacity: 0.9;
	}

	19% {
		opacity: 0.93;
	}

	20% {
		opacity: 0.99;
	}

	24% {
		opacity: 1;
	}

	26% {
		opacity: 0.94;
	}

	28% {
		opacity: 0.98;
	}

	37% {
		opacity: 0.93;
	}

	38% {
		opacity: 0.5;
	}

	39% {
		opacity: 0.96;
	}

	42% {
		opacity: 1;
	}

	44% {
		opacity: 0.97;
	}

	46% {
		opacity: 0.94;
	}

	56% {
		opacity: 0.9;
	}

	58% {
		opacity: 0.9;
	}

	60% {
		opacity: 0.99;
	}

	68% {
		opacity: 1;
	}

	70% {
		opacity: 0.9;
	}

	72% {
		opacity: 0.95;
	}

	93% {
		opacity: 0.93;
	}

	95% {
		opacity: 0.95;
	}

	97% {
		opacity: 0.93;
	}

	to {
		opacity: 1;
	}
}

.Iam {
	padding: 2em 5em;
	font: normal 40px/50px Montserrat, sans-serif;
	color: #999;
	position: absolute;
	top: 0px;
}

.Iam p {
	height: 50px;
	float: left;
	margin-right: 0.3em;
}

.Iam b {
	float: left;
	overflow: hidden;
	position: relative;
	height: 50px;
	top: 0px;
}

.Iam .innerIam {
	display: inline-block;
	color: #e74c3c;
	position: relative;
	white-space: nowrap;
	top: 0;
	left: 0;

	/*animation*/
	-webkit-animation: move 5s;
	-moz-animation: move 5s;
	-ms-animation: move 5s;
	-o-animation: move 5s;
	animation: move 5s;
	/*animation-iteration-count*/
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	/*animation-delay*/
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-ms-animation-delay: 1s;
	-o-animation-delay: 1s;
	animation-delay: 1s;
}

@keyframes move {
	0% {
		top: 0px;
	}

	20% {
		top: -50px;
	}

	40% {
		top: -100px;
	}

	60% {
		top: -150px;
	}

	80% {
		top: -200px;
	}
}

@-webkit-keyframes move {
	0% {
		top: 0px;
	}

	20% {
		top: -50px;
	}

	40% {
		top: -100px;
	}

	60% {
		top: -150px;
	}

	80% {
		top: -200px;
	}
}

@-moz-keyframes move {
	0% {
		top: 0px;
	}

	20% {
		top: -50px;
	}

	40% {
		top: -100px;
	}

	60% {
		top: -150px;
	}

	80% {
		top: -200px;
	}
}

@-o-keyframes move {
	0% {
		top: 0px;
	}

	20% {
		top: -50px;
	}

	40% {
		top: -100px;
	}

	60% {
		top: -150px;
	}

	80% {
		top: -200px;
	}
}

@keyframes move {
	0% {
		top: 0px;
	}

	20% {
		top: -50px;
	}

	40% {
		top: -100px;
	}

	60% {
		top: -150px;
	}

	80% {
		top: -200px;
	}
}


@import url("https://fonts.googleapis.com/css?family=Sacramento&display=swap");

h1 {
	font-size: 90px;
	/*   text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
    0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092;
  color: #fccaff; */
	text-shadow: 0 0 5px #00eeff, 0 0 15px #00f7ff, 0 0 20px #00f7ff, 0 0 40px #00ffdd, 0 0 60px #0011ff, 0 0 10px #0099ff, 0 0 98px #0011ff;
	color: #a9eeff;
	font-family: "Sacramento", cursive;
	text-align: center;
	animation: blink 12s infinite;
	-webkit-animation: blink 12s infinite;
}


@-webkit-keyframes blink {

	20%,
	24%,
	55% {
		color: #111;
		text-shadow: none;
	}

	0%,
	19%,
	21%,
	23%,
	25%,
	54%,
	56%,
	100% {
		/*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
		text-shadow: 0 0 5px #00eeff, 0 0 15px #00f7ff, 0 0 20px #00f7ff, 0 0 40px #00ffdd, 0 0 60px #0011ff, 0 0 10px #0099ff, 0 0 98px #0011ff;
		color: #a9eeff;
	}
}

@keyframes blink {

	20%,
	24%,
	55% {
		color: #111;
		text-shadow: none;
	}

	0%,
	19%,
	21%,
	23%,
	25%,
	54%,
	56%,
	100% {
		/*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
		text-shadow: 0 0 5px #00eeff, 0 0 15px #00f7ff, 0 0 20px #00f7ff, 0 0 40px #00ffdd, 0 0 60px #0011ff, 0 0 10px #0099ff, 0 0 98px #0011ff;
		color: #a9eeff;
	}
}


#wrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: 80em;
}

#container {
	display: flex;
	flex-direction: column;
	float: left;
	justify-content: center;
	min-height: 100%;
	padding: 1em;
	width: 100%;
}

.dream {
	animation: text-shadow 1.5s ease-in-out infinite;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	font-size: 5em;
	font-weight: 900;
	line-height: 1;
}

.dream:hover {
	animation-play-state: paused;
}

@keyframes text-shadow {
	0% {
		transform: translateY(0);
		text-shadow:
			0 0 0 #0c2ffb,
			0 0 0 #2cfcfd,
			0 0 0 #fb203b,
			0 0 0 #fefc4b;
	}

	20% {
		transform: translateY(-1em);
		text-shadow:
			0 0.125em 0 #0c2ffb,
			0 0.25em 0 #2cfcfd,
			0 -0.125em 0 #fb203b,
			0 -0.25em 0 #fefc4b;
	}

	40% {
		transform: translateY(0.5em);
		text-shadow:
			0 -0.0625em 0 #0c2ffb,
			0 -0.125em 0 #2cfcfd,
			0 0.0625em 0 #fb203b,
			0 0.125em 0 #fefc4b;
	}

	60% {
		transform: translateY(-0.25em);
		text-shadow:
			0 0.03125em 0 #0c2ffb,
			0 0.0625em 0 #2cfcfd,
			0 -0.03125em 0 #fb203b,
			0 -0.0625em 0 #fefc4b;
	}

	80% {
		transform: translateY(0);
		text-shadow:
			0 0 0 #0c2ffb,
			0 0 0 #2cfcfd,
			0 0 0 #fb203b,
			0 0 0 #fefc4b;
	}
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation: none !important;
		transition: none !important;
	}
}


.typing-wrapper {
	height: 100vh;
	/*This part is important for centering*/
	display: grid;
	place-items: center;
}

.typing-demo {
	width: 29ch;
	animation: typing 2s steps(22), blonk .5s step-end infinite alternate;
	white-space: nowrap;
	overflow: hidden;
	border-right: 3px solid;
	font-family: monospace;
	font-size: 2em;
}

@keyframes typing {
	from {
		width: 0
	}
}

@keyframes blonk {
	50% {
		border-color: transparent
	}
}

@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

.jt {
	position: relative;
	font-size: 20vmin;
	font-family: 'Staatliches', sans-serif;
	text-transform: uppercase;
	font-display: swap;
	text-shadow: 0 0 10px tomato;
}

.jt__row {
	display: block;
}

.jt__row:nth-child(1) {
	clip-path: polygon(-10% 75%, 110% 75%, 110% 110%, -10% 110%);
}

.jt__row:nth-child(2) {
	clip-path: polygon(-10% 50%, 110% 50%, 110% 75.3%, -10% 75.3%);
}

.jt__row:nth-child(3) {
	clip-path: polygon(-10% 25%, 110% 25%, 110% 50.3%, -10% 50.3%);
}

.jt__row:nth-child(4) {
	clip-path: polygon(-10% 0%, 110% 0%, 110% 25.3%, -10% 25.3%);
}

.jt__row.jt__row--sibling {
	position: absolute;
	top: 0;
	left: 0;
	user-select: none;
}

.jt__text {
	display: block;
	transform-origin: bottom left;
	animation: moveIn 2s 0s cubic-bezier(.36, 0, .06, 1) alternate infinite;
}

.jt__row:nth-child(1) .jt__text {
	transform: translateY(-0.1em);
}

.jt__row:nth-child(2) .jt__text {
	transform: translateY(-0.3em) scaleY(1.1);
}

.jt__row:nth-child(3) .jt__text {
	transform: translateY(-0.5em) scaleY(1.2);
}

.jt__row:nth-child(4) .jt__text {
	transform: translateY(-0.7em) scaleY(1.3);
}

.jt__row:nth-child(5) .jt__text {
	transform: translateY(-0.9em) scaleY(1.4);
}

.jt__row:nth-child(6) .jt__text {
	transform: translateY(-1.1em) scaleY(1.5);
}

@keyframes moveIn {

	50%,
	100% {
		transform: translateY(0em)
	}

	0% {
		opacity: 0;
		filter: blur(10px);

	}

	100% {
		opacity: 1;
		filter: blur(0px);
	}
}



.debug .jt__row:nth-child(even) {
	color: black;
	background: white;
}

.debug .jt__row:nth-child(odd) {
	color: white;
	background: black;
}

.center {
	color: rgba(255, 0, 0, 0.1);
	font-size: 100px;
	text-transform: uppercase;
	font-weight: 700;
	background-size: auto;
	background-image: url(https://i.imgur.com/vdPsMZM.png);
	-webkit-background-clip: text;
	animation: background-text-animation 15s linear infinite;

}

.hm {
	text-shadow: 0px 3px 0px transparent,
		0px 14px 10px rgba(0, 0, 0, 0.15),
		0px 24px 2px rgba(0, 0, 0, 0.1),
		0px 34px 30px rgba(0, 0, 0, 0.1);
}

@keyframes background-text-animation {
	0% {
		background-position: left 0px top 50%;
	}

	50% {
		background-position: left 1500px top 50%;
	}

	100% {
		background-position: left 0px top 50%;
	}
}

.play-pause-pos {
	font-weight: 700;
	font-size: 12px;
	color: #dcdcdc;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 21;
}

#play-button {
	margin-left: 10px;
	height: 15px;
	filter: invert(81%) sepia(48%) saturate(1%) hue-rotate(7deg) brightness(101%) contrast(90%);
	z-index: 21;
}

#pause-button {
	margin-left: 10px;
	height: 15px;
	z-index: 21;
}

.blob {
	background: black;
	position: absolute;
	top: 35vh;
	left: 57vw;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	height: 20px;
	width: 20px;
	transform: scale(2);
	animation: pulse-black 2s infinite;
	opacity: .4;
}

.blob.red {
	background: rgba(255, 82, 82, 1);
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	animation: pulse-red 1s infinite;
	z-index: 11;
	transition: 2s;
}

.blob.red:hover {
	cursor: pointer;
}


@keyframes pulse-red {
	0% {
		transform: scale(0.55);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(2);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}

	100% {
		transform: scale(1.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}


.late-float {
	position: absolute;
	left: 1150px;
	bottom: 260px;
}

.nope-float {
	position: absolute;
	left: 1000px;
	top: 100px;
}

.gare-float {
	position: absolute;
	left: 1300px;
	top: 50px;
}

#projects-icons {
	height: 100px;
	width: 100px;
	animation: float 6s ease-in-out infinite;
}
